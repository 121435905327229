import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Square Pyramid Calculator",
    link: "/square-pyramid-volume-and-area-calculator/",
  },
]
const seeAlsoArray = [
  "/capsule-volume-and-area-calculator",
  "/cone-volume-and-area-calculator",
  "/conical-frustum-volume-and-area-calculator",
  "/cube-volume-and-area-calculator",
  "/cylinder-volume-and-area-calculator",
  "/percentage-calculator",
  "/rectangular-volume-and-area-calculator",
  "/sphere-volume-and-area-calculator",
  "/ellipsoid-volume-and-area-calculator",
]

function SquarePyramidCalculatorVolumeArea(props) {
  let [result, setresult] = useState("")
  let BaseEdge = useRef("")
  let height = useRef("")
  let unitSelector = useRef("")

  function calcVolume(e) {
    let BaseEdgeValue = BaseEdge.current.value
    let heightValue = height.current.value
    let unit = unitSelector.current.value

    if (BaseEdgeValue === "" || isNaN(BaseEdgeValue)) {
      setresult(<strong>Please Enter Base Edge Value</strong>)
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(<strong>Please Enter Height Value</strong>)
    } else {
      BaseEdgeValue = Number(BaseEdgeValue)
      heightValue = Number(heightValue)

      let volume = (1 / 3) * Math.pow(BaseEdgeValue, 2) * heightValue

      setresult(
        <span>
          Volume is : {volume.toFixed(2) + unit}
          <sup>3</sup>
        </span>
      )
    }
  }
  function calcArea(e) {
    let BaseEdgeValue = BaseEdge.current.value
    let heightValue = height.current.value
    let unit = unitSelector.current.value

    if (BaseEdgeValue === "" || isNaN(BaseEdgeValue)) {
      setresult(<strong>Please Enter Base Edge Value</strong>)
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(<strong>Please Enter Height Value</strong>)
    } else {
      BaseEdgeValue = Number(BaseEdgeValue)
      heightValue = Number(heightValue)

      let faceArea =
        (BaseEdgeValue / 2) *
        Math.sqrt(Math.pow(BaseEdgeValue, 2) / 4 + Math.pow(heightValue, 2))
      let lateralArea =
        BaseEdgeValue *
        Math.sqrt(Math.pow(BaseEdgeValue, 2) + 4 * Math.pow(heightValue, 2))
      let surfaceArea =
        Math.pow(BaseEdgeValue, 2) +
        2 *
          BaseEdgeValue *
          Math.sqrt(Math.pow(BaseEdgeValue, 2) / 4 + Math.pow(heightValue, 2))
      let basearea = Math.pow(BaseEdgeValue, 2)

      setresult(
        <span>
          Total surface area :{surfaceArea.toFixed(2) + unit}
          <sup>2</sup>
          <br />
          Lateral surface : {lateralArea.toFixed(2) + unit}
          <sup>2</sup> <br />
          Base area : {basearea.toFixed(2) + unit}
          <sup>2</sup>
          <br />
          Face area of pyramid : {faceArea.toFixed(2) + unit} <sup>2</sup>
        </span>
      )
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Square Pyramid Volume and Area Calculator"
        description="Square Pyramid Volume Calculator, Simple calculation, Pyramid Total Surface area calculator, lateral area of pyramid calculator, Base area of pyramid calculator, the Face area of pyramid calculator, Area, lateral area, base area, face area."
        keywords={[
          "Square Pyramid Calculator, pyramid volume, pyramid face area, pyramid area, pyramid surface area,Pyramid Total Surface area calculator, lateral area of pyramid calculator, Base area of pyramid calculator, Face area of pyramid calculator, piramyd lateral area, pyramid calc, calculate pyramid, pyramid base area, area, volume, pyramid.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Square Pyramid Volume and Area Calculator</Title>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Base Edge (a,b)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={BaseEdge}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Height (h)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={height}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>Select Unit</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Select>
                  <select
                    aria-label="unitSelector"
                    className="staticSelect"
                    ref={unitSelector}
                  >
                    <option value="m">m</option>
                    <option value="cm">cm</option>
                    <option value="mm">mm</option>
                    <option value="km">km</option>
                    <option value="dm">dm</option>
                  </select>
                </Select>
              </Control>
            </Field>
            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calcVolume}
            >
              Calculate Volume
            </Button>
            <Button
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={calcArea}
            >
              Calculate area
            </Button>
            <p className="h5 mt-3">{result}</p>
          </Column>
          <Column>
            <FlexDiv maxWidth="250px" margin="auto">
              <SmallImg
                filename="piramidSquare.png"
                alt="Square Pyramid calculator"
              />
            </FlexDiv>
          </Column>
        </Columns>
        <br />

        <h3>How To Calculate Square Pyramid Volume?</h3>
        <p>
          <strong>
            Volume = Base Edge<sup>2</sup> * height / 3 .
            <br />
            ex : Base Edge = 15, height = 20 . Volume = 15
            <sup>2</sup> * 20 / 3 = 1500 m<sup>3</sup>
          </strong>
          .
        </p>
        <br />
        <h3>Square Pyramid Surface Area?</h3>
        <p>
          <strong>
            Surface Area = Base Edge(a or b)<sup>2</sup> + 2 * Base Edge *
            &radic;
            <span style={{ textDecoration: "overline" }}>
              Base Edge(a or b)<sup>2</sup> / 4 + height<sup>2</sup>
            </span>
          </strong>
        </p>
        <br />
        <h3>Square Pyramid Lateral Area?</h3>
        <p>
          <strong>
            Lateral Area = Base Edge * &radic;
            <span style={{ textDecoration: "overline" }}>
              Base Edge<sup>2</sup> + 4 * height<sup>2</sup>
            </span>
          </strong>
        </p>
        <br />
        <h3>Square Pyramid Base Area?</h3>
        <p>
          <strong>
            Base Area = Base Edge<sup>2</sup> .
          </strong>
        </p>
        <br />
        <h3>Square Pyramid Face Area?</h3>
        <p>
          <strong>
            Face Area = Base Edge / 2 * &radic;
            <span style={{ textDecoration: "overline" }}>
              Base Edge<sup>2</sup> / 4 + height<sup>2</sup>
            </span>
          </strong>
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default SquarePyramidCalculatorVolumeArea
